import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HeaderBread from "../../../components/HeaderBread";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Layout from "../../../Layout";

import { message } from "antd";
import moment from "moment";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import CustomizedTable from "../../../components/UI/CustomizedTable";
import DeletePop from "../../../constants/ErrorPermissionModal";
import {
  deleteNewsLetter,
  exportNewsletter,
  getNewsLetter,
} from "../../../utils/ApiCalls/NewsLetter";
import { checkPermission } from "../../../utils/Validation/checkPermission";
import { deleteAncillary, getAncillary } from "../../../utils/ApiCalls/Ancillary";
import { constants } from "../../../constants/constants";

const Ancillary = () => {
  const navigate = useNavigate();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Ancillary",
      route: "/masters/ancillary-details",
    },
  ];
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [{ permission }, dispatch] = useDataLayerValue();
  const columns = [
    {
      title: "Sl",
      width: 30,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      render: (position, record, index) => ((page - 1) * pageSize) + index + 1,
    },
    {
      title: "Image",
      width: 100,
      dataIndex: "image",
      key: "image",
      render: (image) => (
        <img
          src={`${constants.MEDIAURL}/${image}`}
          style={{ width: "100px", height: "80px" }}
        />
      ),
    },
    {
      title: "Title",
      dataIndex: "english_title",
      key: "english_title",
      width: 50,
    },
    {
      title: "Code",
      dataIndex: "ancillary_code",
      key: "ancillary_code",
      width: 50,
    },


    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (text, record) => (
        <div className="table_action_btn">
          <div className="btn" onClick={() => handleEdit(record.id)}>
            <i className="ri-edit-2-line" data-toggle="tooltip" title="Edit"></i>
          </div>
          <DeletePop onConfirm={() => handleDelete(record.id)}>
            <div>
              {" "}
              <div className="btn delete_btn">
                <i
                  className="ri-delete-bin-line"
                  data-toggle="tooltip"
                  title="Delete"
                ></i>
              </div>
            </div>
          </DeletePop>
        </div>
      ),
    },
  ];
  useEffect(() => {
    getData(page);
  }, [page, pageSize]);

  const getData = async (value) => {
    let data = await getAncillary({ page: value, limit: pageSize });
    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data.data) {
      setRows(data.data);
      setCount(data.count);
    }
  };
  const handleEdit = async (id) => {
    if (checkPermission(permission, "update_per", "NewsLetter")) {
      navigate("/masters/ancillary-details/edit/" + id);
    } else {
      message.error("Update permission missing");
    }
  };
  const handleDelete = async (id) => {
    if (checkPermission(permission, "delete_per", "NewsLetter")) {
      deleteAncillary(id);
      getData();
    } else {
      message.error("Missing Permission for delete");
    }
  };



  return (
    <Layout type={"read_per"} module_data={"Ancillary"}>
      <CustomHeaderTitle title={"Ancillary"} />
      <div>
        <div>
          <HeaderBread
            navigation={navigation}
            title={"Ancillary"}
          ></HeaderBread>
        </div>
        <section className="content" style={{ marginTop: "20px" }}>
          <div className="container-fluid">
            <div className="card">
              <div className="card-header two_item">
                <h5 className="card-title">Ancillary List</h5>
                <div className="two_item">
                  <Link to="/masters/ancillary-details/add" className="btn add_v_btn mx-2">
                    Add Ancillary
                  </Link>


                </div>
              </div>
              <div className="card-body">
                <div className="table_holder">
                  <CustomizedTable
                    setRows={setRows}
                    setPageSize={setPageSize}
                    setPage={setPage}
                    pageSize={pageSize}
                    page={page}
                    count={count}
                    columns={columns}
                    rows={rows}
                    title={"Ancillary"}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Ancillary;
