import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Layout from "../../../Layout";
import HeaderBread from "../../../components/HeaderBread";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import { getNewsLetter } from "../../../utils/ApiCalls/NewsLetter";
import NewsLetterInputs from "../../../components/NewsLetter/NewsLetterInput";
import AncillaryInput from "../../../components/NewsLetter/AncillaryInput";
import { getAncillary } from "../../../utils/ApiCalls/Ancillary";

const EditAncillary = () => {
  const params = useParams();
  const [newsletter, setNewsLetter] = useState({});
  const [{ useDetails }, dispatch] = useDataLayerValue();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Ancillary",
      route: "/masters/ancillary-details",
    },
    {
      title: "Edit Ancillary",
      route: "/masters/ancillary-details/edit",
    },
  ];
  useEffect(() => {
    getData(params?.id);
  }, [params]);

  const getData = async (value) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getAncillary({ id: value });

    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data.count === 1) {
      setNewsLetter(data.data[0]);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  return (
    <Layout type={"update_per"} module_data={"Ancillary"}>
      <CustomHeaderTitle title={"Edit Ancillary"} />
      <HeaderBread
        navigation={navigation}
        title={"Edit Ancillary"}
      ></HeaderBread>
      {newsletter && <AncillaryInput newsletter={newsletter} />}
    </Layout>
  );
};

export default EditAncillary;
