import Api from "../../Api";

export const createAncillary = async (data) => {

  try {
    let res = await Api.post("/ancillary-details", data);
    return res?.data;
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const getAncillary = async (query) => {
  try {
    let res = await Api.get("/ancillary-details", {
      params: query,
    });

    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const exportAncillary = async (query) => {
  try {
    let res = await Api.get("/ancillary-details/export", {
      responseType: "arraybuffer"
    });

    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const deleteAncillary = async (id) => {
  try {
    let res = await Api.delete("/ancillary-details/" + id);

    return res?.data || [];
  } catch (e) {
    return { error: "Failed to delete" };
  }
};

export const updateAncillary = async (id, data) => {
  try {
    let res = await Api.patch("/ancillary-details/" + id, data);

    return res?.data || [];
  } catch (e) {
    return { error: "Failed to Update" };
  }
};
