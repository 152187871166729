import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { constants } from "../../constants/constants";
import { IMAGE_SUB_TYPE, IMAGE_TYPE } from "../../constants/ImageConstant";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import { createAncillary, updateAncillary } from "../../utils/ApiCalls/Ancillary";
import { uploadData } from "../../utils/ApiCalls/Upload";
import { checkFields } from "../../utils/Validation/checkFields";
import UploadFileImageBanner2 from "../Banner/uploadFileImageBanner copy";
import ReactQuill from "react-quill";


const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
    [{ direction: "rtl" }],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

const AncillaryInput = ({ newsletter }) => {
  const [email, setEmail] = useState(newsletter?.email || "");
  const [ancillary_code, setAncillaryCode] = useState([]);
  const [englishTitle, setEnglishTitle] = useState("");
  const [simplifiedChineseTitle, setSimplifiedChineseTitle] = useState("");
  const [traditionalChineseTitle, setTraditionalChineseTitle] = useState("");
  const [description, setDescription] = useState("");
  const [simplifiedChineseDescription, setSimplifiedChineseDescription] = useState("");
  const [traditionalChineseDescription, setTraditionalChineseDescription] = useState("");
  const [, dispatch] = useDataLayerValue();
  const [error, setError] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [traditionalChineseFile, setTraditionalChineseFile] = useState(null);
  const [simplifiedChineseFile, setSimplifiedChineseFile] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (newsletter) {
      setEnglishTitle(newsletter?.english_title);
      setSimplifiedChineseTitle(newsletter.simplified_chinese_title);
      setTraditionalChineseTitle(newsletter?.traditional_chinese_title)
      setDescription(newsletter?.english_description);
      setSimplifiedChineseDescription(
        newsletter?.simplified_chinese_description
      );
      setTraditionalChineseDescription(
        newsletter?.traditional_chinese_description
      );
      // setSelectedFile(newsletter?.image);
      // setSimplifiedChineseFile(newsletter?.simplified_chinese_image);
      // setTraditionalChineseFile(newsletter?.traditional_chinese_image)
      setAncillaryCode(newsletter?.ancillary_code)
    }
  }, [newsletter]);

  const addNewsLetter = async (e) => {
    e.preventDefault();
    try {
      let added = false;
      let dataValues = {
      };
      if (selectedFile) {
        if (typeof selectedFile === "string") {
          dataValues.image = selectedFile.replace(constants.MEDIAURL + "/", "");
        } else {
          let imageType = {
            type: IMAGE_TYPE.AN_IMG,
            subType: IMAGE_SUB_TYPE.ENGLISH_IMG,
          };
          let image = await uploadData(selectedFile, imageType);
          if (image?.error) throw new Error(image.error);

          dataValues.image = image?.url;
        }
      } else {
        dataValues.image = ""
      }
      if (traditionalChineseFile) {
        if (typeof traditionalChineseFile === "string") {
          dataValues.traditional_chinese_image = traditionalChineseFile.replace(constants.MEDIAURL + "/", "");
        } else {
          let imageType = {
            type: IMAGE_TYPE.AN_IMG,
            subType: IMAGE_SUB_TYPE.TRADITIONAL_CHINESE_IMG,
          };
          let image = await uploadData(traditionalChineseFile, imageType);
          if (image?.error) throw new Error(image.error);

          dataValues.traditional_chinese_image = image?.url;
        }
      } else {
        dataValues.traditional_chinese_image = ""
      }
      if (simplifiedChineseFile) {
        if (typeof simplifiedChineseFile === "string") {
          dataValues.simplified_chinese_image = simplifiedChineseFile.replace(constants.MEDIAURL + "/", "");
        } else {
          let imageType = {
            type: IMAGE_TYPE.AN_IMG,
            subType: IMAGE_SUB_TYPE.TRADITIONAL_CHINESE_IMG,
          };
          let image = await uploadData(simplifiedChineseFile, imageType);
          if (image?.error) throw new Error(image.error);

          dataValues.simplified_chinese_image = image?.url;
        }
      }
      else {
        dataValues.simplified_chinese_image = ""
      }



      let valid = checkFields(dataValues);
      if (valid) {
        dispatch({
          type: "SET_ERROR_MESSAGE",
          errMess: { message: valid, type: "error" },
        });
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
        setError(valid);
      } else {
        setError();

        const values = {
          english_title: englishTitle,
          simplified_chinese_title: simplifiedChineseTitle,
          traditional_chinese_title: traditionalChineseTitle,
          ancillary_code: ancillary_code,
          english_description: description,
          simplified_chinese_description: simplifiedChineseDescription,
          traditional_chinese_description: traditionalChineseDescription,
          ...dataValues,
        };

        let data;
        if (newsletter?.id) {
          data = await updateAncillary(newsletter.id, values);
        } else {
          data = await createAncillary(values);
        }
        if (data?.error) {
          dispatch({
            type: "SET_ERROR_MESSAGE",
            errMess: { message: data.error, type: "error" },
          });
          added = false;
        } else {
          added = true;
        }

        console.log({ added, newsletter });
        if (added && !newsletter.id) {
          navigate("/masters/ancillary-details");
        } else {
          navigate("/masters/ancillary-details");
          dispatch({
            type: "SET_ERROR_MESSAGE",
            errMess: { message: "Values updated", type: "success" },
          });
        }
      }
    }
    catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message || "Unknown error occurred",
      });
      dispatch({
        type: "SET_LOADING",
        loading: false,
      });
    }
  };
  return (
    <div>
      <section className="content">
        {error && (
          <div className="d-flex justify-content-center align-items-center text-danger">
            <p>{error}</p>
          </div>
        )}

        <div className="container-fluid">
          <div className="card">
            <form className="input_form" onSubmit={addNewsLetter}>
              <div className="card-body">
                <div className="row">

                  <UploadFileImageBanner2
                    mandatory={"*"}
                    className="col-lg-4"
                    width={200}
                    height={145}
                    mediaType={"image"}

                    image={
                      newsletter?.image
                        ? `${constants.MEDIAURL}/${newsletter?.image}`
                        : newsletter?.image
                    }
                    label={"English Ancillary Image"}
                    placeholder={"Upload English Ancillary Image"}
                    selectedFile={selectedFile}

                    setSelectedFile={setSelectedFile}
                  />
                  <UploadFileImageBanner2
                    mediaType={"image"}
                    mandatory={
                      "*"
                    }
                    className="col-lg-4"
                    width={200}
                    height={145}
                    image={
                      newsletter?.simplified_chinese_image
                        ? `${constants.MEDIAURL}/${newsletter?.simplified_chinese_image}`
                        : newsletter?.simplified_chinese_image
                    }
                    label={"Simplified Chinese Ancillary Image"}
                    placeholder={"Upload Simplified Chinese Ancillary Image "}
                    selectedFile={simplifiedChineseFile}
                    setSelectedFile={setSimplifiedChineseFile}
                  />
                  <UploadFileImageBanner2
                    mediaType={"image"}
                    mandatory={
                      "*"

                    } width={200}
                    height={145}
                    className="col-lg-4"

                    image={
                      newsletter?.traditional_chinese_image
                        ? `${constants.MEDIAURL}/${newsletter?.traditional_chinese_image}`
                        : newsletter?.traditional_chinese_image
                    }
                    label={"Traditional Chinese Ancillary Image "}
                    placeholder={"Upload Traditional Chinese Ancillary Image "}
                    selectedFile={traditionalChineseFile}
                    setSelectedFile={setTraditionalChineseFile}
                  />
                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">
                        English Display Title{" "}
                        {!englishTitle &&
                          !simplifiedChineseTitle &&
                          !traditionalChineseTitle && (
                            <span style={{ color: "red" }}> *</span>
                          )}
                      </label>
                      <input
                        className="f_input"
                        type="text"
                        value={englishTitle}
                        onChange={(e) => {
                          if (e.target.value.length < 44) {
                            setEnglishTitle(e.target.value);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">
                        Simplified Chinese Display Title
                        {!englishTitle &&
                          !simplifiedChineseTitle &&
                          !traditionalChineseTitle && (
                            <span style={{ color: "red" }}> *</span>
                          )}
                      </label>
                      <input
                        // required
                        className="f_input"
                        type="text"
                        value={simplifiedChineseTitle}
                        onChange={(e) => {
                          if (e.target.value.length < 44)
                            setSimplifiedChineseTitle(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">
                        Traditional Chinese Display Title
                        {!englishTitle &&
                          !simplifiedChineseTitle &&
                          !traditionalChineseTitle && (
                            <span style={{ color: "red" }}> *</span>
                          )}
                      </label>
                      <input
                        // required
                        className="f_input"
                        type="text"
                        value={traditionalChineseTitle}
                        onChange={(e) => {
                          if (e.target.value.length < 44)
                            setTraditionalChineseTitle(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="fieldrow">
                      <label className="f_label">English Description2</label>
                      <ReactQuill
                        value={description}
                        onChange={setDescription}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="fieldrow">
                      <label className="f_label">
                        Simplified Chinese Description2
                      </label>
                      <ReactQuill
                        value={simplifiedChineseDescription}
                        onChange={setSimplifiedChineseDescription}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="fieldrow">
                      <label className="f_label">
                        Traditional Chinese Description2
                      </label>
                      <ReactQuill
                        value={traditionalChineseDescription}
                        onChange={setTraditionalChineseDescription}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Ancillary Code</label>
                      <input
                        required
                        className="f_input"
                        type="text"
                        value={ancillary_code}
                        onChange={(e) => setAncillaryCode(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer text-right">
                <button className="btn main_submit">
                  {newsletter.id ? "Update" : "Add"} Ancillary
                </button>
              </div>
            </form>
          </div>
          <div className="pt-3"></div>
        </div>
      </section>
    </div>
  );
};

export default AncillaryInput;
