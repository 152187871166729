import { FormControlLabel, Switch } from "@mui/material";
import { message } from "antd";
import React, { lazy, useEffect, useState } from "react";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Logs from "../../../components/Logs";
import SwitchBar from "../../../components/Pages/SwitchBar";
import Custom2StageForm from "../../../components/template/Componets/Custom2StageForm/Custom2StageForm";
import CardForm from "../../../components/template/Componets/Custom2StageForm/CustomForm";
import Level3Card from "../../../components/template/Componets/DealCard/Level3Card";
import PageSEO from "../../../components/template/Componets/PageSEO/PageSEO";
import { clearCache, uploadFile } from "../../../utils/ApiCalls/Document";
import {
  createWebpage,
  getShips,
  getWebpage,
  updatePageComponents,
  updateWebpage,
} from "../../../utils/ApiCalls/WebPages";
import { checkFields } from "../../../utils/Validation/checkFields";
import { checkFormFields } from "../../../utils/Validation/checkFormFields";
import { addMenuList } from "../../../utils/ApiCalls/MenuList";
import CustomComponent from "../../../components/template/Componets/Custom2StageForm/CustomComponent";
import Level4Card from "../../../components/template/Componets/DealCard/Level4Card";
import Layout from "../../../Layout";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import Swal from "sweetalert2";
import AllVersion from "./AllVersion";
import { addlog } from "../../../utils/ApiCalls/Logs";

const CustomTemplates = ({
  slug,
  attributes,
  page_type,
  webpage_slug,
  url,
}) => {
  //versioning changes
  // const [activetab, setActiveTab] = useState(slug ? "version" : "english");

  const [activetab, setActiveTab] = useState("english");
  const [dataattributes, setDataattributes] = useState(attributes);
  const [active, setActive] = useState(false);
  const [data, setData] = useState();
  const [version, setVersion] = useState();
  const [error, setError] = useState([]);
  const [{ userDetails, permission }, dispatch] = useDataLayerValue();
  const [pageData, setPageData] = useState({
    slug,
    location: [],
    component: [],
  });

  const handlePreview = (event) => {
    setActive(event.target.checked);
  };

  const handleChange = (index, data) => {
    setPageData((prevState) => {
      let arrayData = [...(prevState?.component || [])];

      if (index >= arrayData.length) {
        arrayData = [
          ...arrayData,
          ...Array(index - arrayData.length + 1).fill({}),
        ];
      }

      arrayData[index] = data;

      return {
        ...prevState,
        component: arrayData,
      };
    });
  };

  const handleDelete = (dataindex) => {
    setPageData((item) => ({
      ...item,
      component: item.component.filter((item, index) => index != dataindex),
    }))
    setDataattributes((item) => ({
      ...item,
      component: item.component.filter((item, index) => index != dataindex),
    }));

  }
  const handleUp = (index) => {
    if (index > 0) {
      setPageData((prevData) => {
        const val = [...prevData.component]; // Create a shallow copy of the array
        [val[index - 1], val[index]] = [val[index], val[index - 1]]; // Swap elements
        return { ...prevData, component: val };
      });
    }
  };

  const handleDown = (index) => {
    if (index < pageData.component.length - 1) {
      setPageData((prevData) => {
        const val = [...prevData.component]; // Create a shallow copy of the array
        [val[index], val[index + 1]] = [val[index + 1], val[index]]; // Swap elements
        return { ...prevData, component: val };
      });
    }
  };
  const fileKeys = [
    "image",
    "image1",
    "image2",
    "image3",
    "image4",
    "image5",
    "arabic_image",
    "simplified_chinese_image",
    "traditional_chinese_image",
    "english_file",
    "simplified_chinese_file",
    "traditional_chinese_file",
    "arabic_file",
    "logo",
    "simplified_chinese_logo",
    "traditional_chinese_logo",
    "arabic_logo",
    "english_file_1",
    "simplified_chinese_file_1",
    "traditional_chinese_file_1",
    "arabic_file_1",
    "english_file_2",
    "simplified_chinese_file_2",
    "traditional_chinese_file_2",
    "arabic_file_2",
  ];
  const uploadImagesRecursively = async (data) => {

    for (const key in data) {

      if (data[key] && typeof data[key] === "object") {

        for (const fileKey of fileKeys) {

          if (data[key][fileKey])
            if (
              data[key][fileKey] !== undefined &&
              data[key][fileKey] !== null
            ) {
              data[key][fileKey] = await handleFileUpload(data[key][fileKey], fileKey);

            } else {
              data[key][fileKey] = ""
            }
        }

        await uploadImagesRecursively(data[key]);
      }
    }
  };

  const addField = (attributes) => {
    setDataattributes((item) => ({
      ...item,
      component: [...item.component, attributes],
    }));
  };
  useEffect(() => {
    // "Version Changes"
    // if (slug && version) getData(slug, version);
    if (slug) getData(slug);
  }, [slug, version]);

  const setLayout = (pageData) => {
    if (pageData && pageData.page_type === "blog_item" && dataattributes) {
      const componentCount = pageData.component.length;
      const dataCount = dataattributes.component.length;

      if (componentCount > dataCount) {
        const missingSections = componentCount - dataCount;
        const newSections = Array(missingSections).fill(
          attributes.component[attributes.component.length - 1]
        );
        setDataattributes((prev) => ({
          ...prev,
          component: [...prev.component, ...newSections],
        }));
      }
    }
  };
  async function handleFileUpload(selectedFile, fileKey) {
    if (typeof selectedFile === "string") {
      return selectedFile; // Return the string if it's already a URL
    } else {
      let real;
      if (fileKey?.toString()?.includes("file")) {
        real = "yes"
      }
      const uploadedFile = await uploadFile(selectedFile, {}, real);
      if (uploadedFile?.error) throw new Error(uploadedFile.error);
      return uploadedFile.url;
    }
  }

  const addData = async (is_active) => {

    let checkValid = true;
    let errors = attributes.component.map((item, index) => {
      let error = checkFormFields(
        pageData?.component?.[index],
        item?.fields?.filter((item) => item.required).map((item) => item.key) ||
        []
      );
      if (error.length > 0) {
        checkValid = false;
      }
      return error;
    });
    if (!checkValid) {
      message.error("Some fields are missing");
      setError(errors);
    } else {
      setError([]);
      dispatch({
        type: "SET_LOADING",
        loading: true,
      });
      let dataValues = pageData;
      let added = false;

      console.log({ dataValues })

      await uploadImagesRecursively(dataValues);
      dataValues.slug = dataValues.slug || slug
        ||
        dataValues?.title?.toLowerCase().replaceAll(" ", "-");
      dataValues.page_type = page_type || "deal";
      dataValues.is_active = 1
      let valid = checkFields(dataValues);
      if (valid) {
        dispatch({
          type: "SET_ERROR_MESSAGE",
          errMess: { message: valid, type: "error" },
        });
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
      } else {
        const values = {
          ...dataValues,
        };

        let data;
        // "Version Changes"
        // if (pageData?.id && pageData?.version_is_active == 0) {

        if (pageData?.id) {
          data = await updateWebpageData(pageData.id, values);
        } else {
          // "Version Changes"
          // values.version_is_active = is_active || 0
          values.version_is_active = 0
          data = await createWebpage(values);

          if (data?.message) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: data?.message || "Unknown error occurred",
            });
            dispatch({
              type: "SET_LOADING",
              loading: false,
            });
          }
          else {
            if (webpage_slug && !pageData?.id) {

              let data = {
                url: url + "/" + dataValues.slug,
                webpage_slug,
                menuName: dataValues?.menuName,
                traditionalChineseMenuName: dataValues.traditionalChineseMenuName,
                simplifiedChineseMenuName: dataValues.simplifiedChineseMenuName,
                arabicMenuName: dataValues.arabicMenuName,
              };
              addMenuList(data);
            }
            dispatch({
              type: "SET_LOADING",
              loading: false,
            });
            getData(dataValues.slug || slug || dataValues?.title?.toLowerCase().replaceAll(" ", "-"));
          }

        }

        if (data?.error || data?.message) {
          dispatch({
            type: "SET_ERROR_MESSAGE",
            errMess: { message: data.error, type: "error" },
          });

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data.message || "Unknown error occurred",
          });
          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
          added = false;
          message.error(data?.message || "Webpage added");
        } else {
          console.log(data?.webpageData?.version)
          setVersion(data?.webpageData?.version)
          if (is_active == 1) {
            addlog(
              (data?.webpageData?.version || 0) + " Webpage version published",
              slug,
              { date: new Date() },
              userDetails.id
            );
          } else {
            addlog(
              "New webpage version Created",
              slug,
              { date: new Date() },
              userDetails.id
            );
          }
          message.success("Webpage updated");
          added = true;
        }
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
      }
    }
  };

  const clearData = async () => {
    let data = await clearCache();
    if (data) {
      message.success("Cache Referesh Started")
    }
  }
  const updateWebpageData = async (id, data) => {
    try {
      let { component, ...rest } = data;

      let webpage = await updateWebpage(id, rest);

      await Promise.all(
        component.map(async (item, index) => {
          let value = {
            ...item,
            page_id: id,
            index,
            component_name: index.toString(), // Convert index to string
          };
          await updatePageComponents(value); // Await the updateSubComponent call
        })
      );
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message || "Unknown error occurred",
      });
      console.error("Error updating webpage data:", error);
    }
  };

  const updateComponentData = async (values, index) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });

    // if (pageData?.is_active == 1) {
    //   addData(0)
    // } else {
    let dataValues = {
      component: [{
        ...values, page_id: pageData.id,
        index,
        component_name: index.toString()
      }]

    };
    try {

      await uploadImagesRecursively(dataValues);
      await updatePageComponents(dataValues.component[0])
      message.success("updated")
      addlog(
        pageData?.version + " Webpage version updated",
        slug,
        { date: new Date() },
        userDetails.id
      );
      getData(slug)
      dispatch({
        type: "SET_LOADING",
        loading: false,
      });
    }

    catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.error,
      });
      message.success("failed to update")
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
    // }
  }

  const getData = async (slug) => {

    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getWebpage({ slug: slug, version: version || "0" });
    let values = await getShips({ location_id: 1, menubarId: 2 });

    if (values.data) {
      dispatch({
        type: "SET_SHIPS",
        ships: values?.data?.map((item) => ({
          id: item?.url.replace("our-ship/", "").replaceAll("-", "_"),
          name: item?.menuName,
        })),
      });
    }
    setLayout(data)
    setPageData(data);
    setData(data);

    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  return (
    <div>
      <Layout key={slug} type={"create_per"} module_data={"webpages"} allow>
        <CustomHeaderTitle key={slug} slug={slug} title={slug} />
        <div>
          <section className="content">
            <div className="container-fluid">
              {/* // "Version Changes" */}
              {/* {slug && <p style={{ color: "green" }}>You are currently editing version {data?.version}</p>} */}

              <SwitchBar
                tabs={slug ? [
                  // "Version Changes"
                  // { title: "All Versions", value: "version" },
                  { title: "English", value: "english" },
                  { title: "Simplified Chinese", value: "simplified_chinese" },
                  { title: "Traditional Chinese", value: "treditonal_chinese" },
                  { title: "Arabic", value: "arabic" },
                  // { title: "History Logs", value: "logs" },
                ] : [

                  { title: "English", value: "english" },
                  { title: "Simplified Chinese", value: "simplified_chinese" },
                  { title: "Traditional Chinese", value: "treditonal_chinese" },
                  { title: "Arabic", value: "arabic" }
                ]}
                activeTab={activetab}
                setActiveTab={setActiveTab}
              />
              {["english", "simplified_chinese", "treditonal_chinese", "arabic"].includes(activetab) && <div className="input_form" onSubmit={() => { }}>
                <div className="row">
                  <div className="col-lg-12">
                    <FormControlLabel
                      control={
                        <Switch checked={active} onChange={handlePreview} />
                      }
                      label="Preview All"
                    />

                    <PageSEO
                      webpage_slug={webpage_slug}
                      activetab={activetab}
                      cardData={pageData}
                      setCardData={setPageData}
                      page_type={page_type}
                    />

                    {dataattributes.component.map((item, index) => {
                      return item.type === 1 ? (
                        <CardForm
                          attributes={item.fields}
                          rootData={pageData}
                          key={index}
                          page_id={data?.id}
                          oldData={data?.component?.[index]}
                          preview={active}
                          error={error?.[index]}
                          handleUp={handleUp}
                          handleDown={handleDown}
                          activetab={activetab}
                          cardData={pageData?.component?.[index] || {}}
                          updateComponentData={(data) => updateComponentData(data, index)}
                          setCardData={(item) => {
                            handleChange(index, item);
                          }}
                        >
                          {item.preview(
                            activetab,
                            pageData?.component?.[index] || {}
                          )}
                        </CardForm>
                      ) : item.type === 2 ? (
                        <Custom2StageForm
                          level2={item.level1}
                          key={index}
                          page_id={data?.id}
                          rootData={pageData}
                          handleUp={handleUp}
                          handleDown={handleDown}
                          updateComponentData={(data) => updateComponentData(data, index)}
                          error={error?.[index]}
                          level1={item.fields}
                          oldData={data?.component?.[index]}
                          preview={active}
                          dataCard={
                            pageData?.component?.[index] || { dataArray: [] }
                          }
                          setDataCard={(item) => {
                            handleChange(index, item);
                          }}
                          activetab={activetab}
                        >
                          {item.preview(
                            activetab,
                            pageData?.component?.[index] || {}
                          )}
                        </Custom2StageForm>
                      ) : item.type === 3 ? (
                        <Level3Card
                          level2={item.level2}
                          level1={item.level1}
                          page_id={data?.id}
                          updateComponentData={(data) => updateComponentData(data, index)}
                          handleUp={handleUp}
                          handleDown={handleDown}
                          key={index}
                          rootData={pageData}
                          oldData={data?.component?.[index]}
                          preview={active}
                          fields={item.fields}
                          error={error?.[index]}
                          dataCard={
                            pageData?.component?.[index] || { dataArray: [] }
                          }
                          setDataCard={(item) => {
                            handleChange(index, item);
                          }}
                          activetab={activetab}
                        >
                          {item.preview(
                            activetab,
                            pageData?.component?.[index] || {}
                          )}
                        </Level3Card>
                      ) : item.type === 4 ? (
                        item.component(
                          data?.component?.[index],
                          active,
                          pageData?.component?.[index],
                          (item) => {
                            handleChange(index, item);
                          },
                          activetab,
                          item
                        )
                      ) : item.type === 5 ? (
                        <CustomComponent
                          item={item}
                          index={index}
                          key={index}
                          page_id={data?.id}
                          updateComponentData={(data) => updateComponentData(data, index)}
                          handleDelete={handleDelete}
                          show={index === dataattributes.component.length - 1}
                          attributes={item.fields}
                          handleUp={handleUp}
                          handleDown={handleDown}
                          rootData={pageData}
                          oldData={data?.component?.[index]}
                          preview={active}
                          error={error?.[index]}
                          activetab={activetab}
                          addField={addField}
                          cardData={pageData?.component?.[index] || {}}
                          setCardData={(item) => {
                            handleChange(index, item);
                          }}
                        >
                          {item.preview(
                            activetab,
                            pageData?.component?.[index] || {}
                          )}
                        </CustomComponent>
                      ) : item.type === 6 ? (
                        <Level4Card
                          level2={item.level2}
                          level1={item.level1}
                          level3={item.level3}
                          page_id={data?.id}
                          updateComponentData={(data) => updateComponentData(data, index)}
                          key={index}
                          rootData={pageData}
                          oldData={data?.component?.[index]}
                          preview={active}
                          handleUp={handleUp}
                          handleDown={handleDown}
                          fields={item.fields}
                          error={error?.[index]}
                          dataCard={
                            pageData?.component?.[index] || { dataArray: [] }
                          }
                          setDataCard={(item) => {
                            handleChange(index, item);
                          }}
                          activetab={activetab}
                        >
                          {item.preview(
                            activetab,
                            pageData?.component?.[index] || {}
                          )}
                        </Level4Card>
                      ) : (
                        <div key={index}></div>
                      );
                    })}

                    <div className="text-right" style={{ marginBottom: "10px" }}>
                      {slug == "brochures-guides" &&
                        <button
                          onClick={() => clearData()}
                          className="btn main_submit"
                          style={{ marginRight: "10px" }}
                        >
                          Clear Cache
                        </button>}



                      <button
                        onClick={() => addData(1)}
                      className="btn main_submit"
                      >
                        Add Page
                      </button>

                    </div>
                  </div>
                </div>
              </div>}
              {slug && activetab == "version" && <AllVersion slug={slug} version={version} setVersion={setVersion} setActiveTab={setActiveTab} />}
              {slug && activetab === "logs" && (
                <Logs
                  hidetitle
                  type={slug}
                />
              )}
            </div>
          </section>
        </div>
      </Layout></div>
  );
};

export default CustomTemplates;
