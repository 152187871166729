import React from "react";
import BannerInputs from "../../../components/Banner/BannerInputs";
import HeaderBread from "../../../components/HeaderBread";
import Layout from "../../../Layout";
import { useNavigate } from "react-router-dom";
import ExperienceInputs from "../../../components/Experience/ExperienceInputs";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import NewsLetterInputs from "../../../components/NewsLetter/NewsLetterInput";
import AncillaryInput from "../../../components/NewsLetter/AncillaryInput";

const CreateAncillary = () => {
  const navigate = useNavigate();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Ancillary",
      route: "/masters/ancillary-details",
    },
    {
      title: "Add Newsletter",
      route: "/masters/ancillary-details/add",
    },
  ];

  return (
    <Layout type={"create_per"} module_data={"Ancillary"}>
      <CustomHeaderTitle title={"Create Ancillary"} />
      <HeaderBread
        navigation={navigation}
        title={"Add Ancillary"}
      ></HeaderBread>
      <AncillaryInput newsletter={{}} />
    </Layout>
  );
};

export default CreateAncillary;
